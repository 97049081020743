select{
  width: 200px;
  font-size: 16px;
  padding: 8px 16px;
  margin-right: 20px;
  cursor: pointer;
}

button{
  padding: 8px;
  margin-right: 20px;
  border: 2px solid #000;
  cursor: pointer;
  font-size: 16px;
}
a{
  cursor: pointer;
}
